<template>
  <div class="container">
    <div class="header">
      <div class="left" @click="onGoBack">
        <img :src="goBackImg" />
      </div>
      <div class="center">
        用户星球
      </div>
      <div class="right"></div>
    </div>
    <div class="main">
      <!-- <div class="phone">
                {{ phone }}
            </div> -->

      <div class="item" v-for="(item, index) in remarkList" :key="index" @click="onViewRemark(item)">
        <div class="body">
          {{ item.userCont }}
        </div>
        <div class="user-info">
          {{ item.ownCourse }}
        </div>
        <div class="attach-info">
          <div class="img" v-for="attach in item.userAttach" :key="attach.key">
            <img :src="attach.url" v-if="attach.fileType == 'image'" />
            <video :src="attach.url" v-else />
          </div>
        </div>
        <div class="user-info">{{ item.userPhone }}, {{ item.createTime | ymdStr }}</div>
      </div>
    </div>
    <!-- 
        <div class="footer">
            <footerMenu></footerMenu>
        </div> -->
    <div class="float-add" @click="onAddUserRemark">
      <img :src="addBtnImg" />
    </div>
    <van-popup v-model="showSingleView" round position="bottom" :style="{ height: '70%' }">
      <div class="user-remark-widget">
        <div class="user-remark-cont">
          {{ currentRemark.userCont }}
        </div>
        <div class="user-remark-attach">
          <div class="img" v-for="attach in currentRemark.userAttach" :key="attach.key">
            <img :src="attach.url" v-if="attach.fileType == 'image'" />
            <video :src="attach.url" v-else />
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { TouchEmulator } from "@/util/touch.js";
import footerMenu from "@/components/footerMenu.vue";
import csWidget from "@/components/csWidget.vue";
import activeCourseWidget from "@/components/activeCourseWidget.vue";
import logoutWidget from "@/components/logoutWidget.vue";
import { epochToDateString, epochToYMD } from "@/util/timeTool.js";
import { Notify } from "vant";
import { userRemarkApi } from "@/api/userRemarkApi.js";
import { userCenterApi } from "@/api/userCenterApi.js";
import upload from "@/components/upload.vue";
import { matchFileType } from "@/util/validate";
import { Popup } from "vant";
export default {
  components: {
    //upload,
  },
  data() {
    return {
      bizType: "user-operation",
      goBackImg: require("@/assets/images/go-back.png"),
      addBtnImg: require("@/assets/images/user-remark-add-btn.png"),
      taskId: 0,
      start: 0,
      len: 10,
      remarkList: [],
      currentRemark: {},
      showSingleView: false,
    };
  },
  methods: {
    onGoBack() {
      this.$router.push({
        path: "/",
      });
    },
    onUid() {
      userCenterApi.uid().then((uid_ret) => {
        if (uid_ret.code == 0 && uid_ret.data) {
          this.phone = uid_ret.data.phone;
        } else {
          Notify({
            type: "danger",
            message: "获取用户信息失败",
          });
        }
      });
    },
    onAddUserRemark() {
      this.$router.push({
        path: "/profile/userRemarkAdd",
        query: {
          taskId: this.taskId,
        },
      });
    },
    getTaskId() {
      if (this.$route.query && this.$route.query.taskId) {
        this.taskId = this.$route.query.taskId;
      }
      return this.taskId;
    },
    onPager() {
      this.getTaskId();
      userRemarkApi
        .pager(this.taskId, this.start, this.len)
        .then((ret) => {
          let { code, datas } = ret;
          if (code == 0) {
            //console.log('add ok...', ret);
            console.log("..onPager..", datas);
            if (datas && datas.length > 0) {
              for (let item of datas) {
                this.wrapAttach(item.userAttach);
                this.remarkList.push(item);
              }
            }
          }
        })
        .catch((err) => {
          console.log(err);
          Notify({
            type: "danger",
            message: "拉去评论失败，可能是服务器睡着了",
          });
        });
    },
    onScroll() {
      let el = document.querySelector(".main");
      // offsetHeight是一个只读属性，offsetHeight是一个元素的content+padding+border+margin+scroll bar的高度。也是在可是范围内这些高度的相加
      // 一个元素的 scrollTop 值是这个元素的内容顶部（卷起来的）到它的视口可见内容（的顶部）的距离的度量。当一个元素的内容没有产生垂直方向的滚动条，那么它的 scrollTop 值为0。
      // scrollHeight 属性是一个只读属性，它返回该元素的像素高度，高度包含内边距（padding），不包含外边距（margin）、边框（border），是一个整数，单位是像素 px。
      //console.log(el.offsetHeight, el.scrollTop, el.scrollHeight);
      if (el.offsetHeight + el.scrollTop >= el.scrollHeight - 20) {
        console.log("到底部了");
        this.start += this.len;
        this.onPager();
      }
    },
    bindScroll() {
      document.querySelector(".main").addEventListener("scroll", this.onScroll);
    },
    onViewRemark(remark) {
      this.showSingleView = true;
      this.currentRemark = remark;
      console.log(remark);
      // this.$userRemark
      //     .get({ query: { taskId: taskId, id: id } })
      //     .then((ret) => {
      //         console.log(ret);
      //     })
      //     .catch((err) => {
      //         console.log(err);
      //     });
    },
    wrapAttach(itemList) {
      for (let item of itemList) {
        item.fileType = matchFileType(item.key);
      }
    },
  },
  mounted() {
    this.onUid();
    this.getTaskId();
    this.onPager();
    this.bindScroll();
    //console.log(epochToYMD(111));
  },
  destroyed() {
    document.querySelector(".main").removeEventListener("scroll", this.onScroll);
  },
  filters: {
    ymdStr: function(value) {
      if (!value) return "";
      value = epochToYMD(value);
      return value;
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  display: flex;
  flex-direction: column;
  background: rgb(245, 246, 248);
  height: 100%;
  .header {
    // display: flex;
    // flex-direction: column;
    // height: 62px;
    // background: #ffffff;
    // font-size: 18px;
    // color: #323233;
    // letter-spacing: 0.4px;
    height: 68px;
    flex: 0 0 auto;
    background: #ffffff;
    display: flex;
    flex-direction: row;
    .left {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 27px;
      img {
        margin-left: 16px;
        height: 23px;
        width: auto;
      }
    }
    .center {
      flex: 1;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-size: 20px;
      font-weight: 500;
      color: #323233;
      letter-spacing: 0.44px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0 10px;
    }
    .right {
      width: 27px;
    }
  }
  .main {
    margin: 14px 14px 0px 14px;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    flex: 1 1 auto;
    align-items: center;
    background: #ffffff;
    border-radius: 8px 8px 0px 0px;
    .item {
      background: #fafafa;
      border-radius: 8px;
      width: 324px;
      margin: 10px;
      .body {
        font-size: 16px;
        color: #505051;
        letter-spacing: 0.36px;
        margin: 6px 20px 6px 20px;
        //height: 68px;
        overflow: hidden;
        text-overflow: ellipsis; //显示省略号
        display: -webkit-box;
        -webkit-line-clamp: 3; //块元素显示的文本行数
        -webkit-box-orient: vertical;
      }
      .user-info {
        font-size: 11px;
        color: #9298a4;
        letter-spacing: 0.24px;
        margin: 2px 20px 6px 20px;
      }
      .attach-info {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 0px 10px 10px 20px;
        .img {
          height: 54px;
          width: 54px;
          margin: 12px 12px 0px 0px;
          position: relative; // 父节点要设置relative
          border-radius: 8px;
          overflow: hidden;
          img,
          video {
            height: 54px;
            width: 54px;
          }
        }
      }
    }
  }
  .footer {
    flex: 0 0 auto;
  }
  .float-add {
    height: 44px;
    width: 44px;
    position: absolute;
    right: 24px;
    bottom: 48px;
    z-index: 1;
    background: #4d52d1;
    border-radius: 22px;
    display: flex;
    justify-content: center;

    color: #ffffff;
    font-size: 30px;
    line-height: 44px;
  }
}
.user-remark-widget {
  background: #ffffff;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  .user-remark-cont {
    display: flex;
    flex-direction: row;
    text-align: left;
    margin: 20px;
    font-size: 16px;
    color: #505051;
    letter-spacing: 0.36px;
    overflow-y: scroll;
  }
  .user-remark-attach {
    display: flex;
    flex-direction: column;
    //flex-wrap: wrap;
    margin: 0px 10px 10px 20px;
    align-items: center;
    .img {
      height: 325px;
      width: 325px;
      margin: 12px 12px 0px 0px;
      img,
      video {
        height: 325px;
        width: 325px;
      }
    }
  }
}
</style>

<style lang="less"></style>
