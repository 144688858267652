export const epochToDateString = function(epoch) {
  if (typeof epoch == "string") {
    epoch = parseInt(epoch);
  }
  let date = new Date(epoch);
  let Y = date.getFullYear() + "-";
  let M = (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) + "-";
  let D = date.getDate() < 10 ? "0" + date.getDate() + " " : date.getDate() + " ";
  let h = date.getHours() < 10 ? "0" + date.getHours() + ":" : date.getHours() + ":";
  let m = date.getMinutes() < 10 ? "0" + date.getMinutes() + ":" : date.getMinutes() + ":";
  let s = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();

  return Y + M + D + h + m + s;
};

export const epochToDateYMDHM = function(epoch) {
  if (typeof epoch == "string") {
    epoch = parseInt(epoch);
  }
  let date = new Date(epoch);
  let Y = date.getFullYear() + "-";
  let M = (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) + "-";
  let D = date.getDate() < 10 ? "0" + date.getDate() + " " : date.getDate() + " ";
  let h = date.getHours() < 10 ? "0" + date.getHours() + ":" : date.getHours() + ":";
  let m = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();

  return Y + M + D + h + m;
};

export const epochToDateYMDHMStr = function(epoch) {
  if (typeof epoch == "string") {
    epoch = parseInt(epoch);
  }
  let date = new Date(epoch);
  let Y = date.getFullYear() + ".";
  let M = (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) + ".";
  let D = date.getDate() < 10 ? "0" + date.getDate() + " " : date.getDate() + " ";
  let h = date.getHours() < 10 ? "0" + date.getHours() + ":" : date.getHours() + ":";
  let m = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();

  return Y + M + D + h + m;
};

export const epochToYMD = function(epoch) {
  if (typeof epoch == "string") {
    epoch = parseInt(epoch);
  }
  let date = new Date(epoch);
  let Y = date.getFullYear() + "/";
  let M = (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) + "/";
  let D = date.getDate() < 10 ? "0" + date.getDate() + " " : date.getDate() + " ";
  return Y + M + D;
};
